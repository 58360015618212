.card-container p {
	margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
	margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
	padding: 16px;
	background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
	display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
	background: #f0f0f0;
	color: #8e8e8e;
	border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
	background: #fff;
	border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
	padding: 24px;
	overflow: hidden;
	background: #f5f5f5;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
	height: 120px;
	margin-top: -8px;
}

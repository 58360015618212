@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

body {
	margin: 0;
	font-family: 'Fira Sans', Arial, Monaco, Tahoma, 'Courier New', sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.icon:hover {
	color: #005eaa;
	cursor: pointer;
}

.icon {
	color: #8e8e8e;
}

.tableC tbody tr {
	cursor: pointer;
}

/* Centre Alignment for ANTD Icon */
svg {
	vertical-align: baseline;
}

.code-box-demo {
	overflow-x: auto;
}

.code-box-demo .ant-segmented {
	margin-bottom: 10px;
}

/* Remove spinner/dropdown from time input */
.time-input-no-spinner::-webkit-calendar-picker-indicator,
.time-input-no-spinner::-webkit-inner-spin-button,
.time-input-no-spinner::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
}

.time-input-no-spinner {
    -moz-appearance: textfield;
}

/* Optional: Remove the dropdown arrow in Firefox */
.time-input-no-spinner::-moz-calendar-picker-indicator {
    display: none;
}




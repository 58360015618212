.react-kanban-board {
	padding: 5px;
}

.react-kanban-card-skeleton,
.react-kanban-card,
.react-kanban-card-adder-form {
	box-sizing: border-box;
	max-width: 250px;
	min-width: 250px;
}
.react-kanban-card--dragging {
	box-shadow: 2px 2px grey;
}

.react-kanban-column {
	padding: 15px;
	border-radius: 2px;
	margin: 5px;
}
.react-kanban-column input:focus {
	outline: none;
}

.react-kanban-column-header {
	padding-bottom: 10px;
	font-weight: bold;
}
.react-kanban-column-header input:focus {
	outline: none;
}
.react-kanban-column-header__button {
	color: #333333;
	background-color: #ffffff;
	border-color: #cccccc;
}
.react-kanban-column-header__button:hover,
.react-kanban-column-header__button:focus,
.react-kanban-column-header__button:active {
	background-color: #e6e6e6;
}
